html, body, #root {
}

.fixed-container {
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    z-index: 10000;
}
